// extracted by mini-css-extract-plugin
export var bodyBase = "tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodyLarge = "tournament-director-attributes-module--body-large--b4d9d tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodyLargeBold = "tournament-director-attributes-module--body-large-bold--98936 tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodyRegular = "tournament-director-attributes-module--body-regular--9db57 tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodyRegularBold = "tournament-director-attributes-module--body-regular-bold--5cd24 tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodySmall = "tournament-director-attributes-module--body-small--9a434 tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var bodySmallBold = "tournament-director-attributes-module--body-small-bold--380d1 tournament-director-attributes-module--body-base--21c22 tournament-director-attributes-module--site-font--41c06";
export var borderTop = "tournament-director-attributes-module--border-top--a277e";
export var breakWordContainer = "tournament-director-attributes-module--break-word-container--68007";
export var buttonIconBase = "tournament-director-attributes-module--button-icon-base--d6177";
export var clickLink = "tournament-director-attributes-module--click-link--f3d87";
export var dropdownBase = "tournament-director-attributes-module--dropdown-base--3d48c";
export var dropdownSelectBase = "tournament-director-attributes-module--dropdown-select-base--5c893 tournament-director-attributes-module--text-input--c7f16";
export var flexCol = "tournament-director-attributes-module--flex-col--abaa5";
export var formErrorMessage = "tournament-director-attributes-module--form-error-message--29fde";
export var h3 = "tournament-director-attributes-module--h3--59659";
export var h4 = "tournament-director-attributes-module--h4--17782";
export var hoverLink = "tournament-director-attributes-module--hover-link--60a7f";
export var hoverRow = "tournament-director-attributes-module--hover-row--9d27c";
export var inviteExpired = "tournament-director-attributes-module--invite-expired--552f5";
export var membershipContainer = "tournament-director-attributes-module--membership-container--bbf9b tournament-director-attributes-module--flex-col--abaa5 tournament-director-attributes-module--primary-border--c76e2";
export var membershipHeader = "tournament-director-attributes-module--membership-header--c6311";
export var membershipHeading = "tournament-director-attributes-module--membership-heading--84fbe";
export var membershipLabel = "tournament-director-attributes-module--membership-label--f8947";
export var moreFiltersBorderClass = "tournament-director-attributes-module--more-filters-border-class--d2d40";
export var pageBg = "tournament-director-attributes-module--page-bg--9a7a4";
export var pointer = "tournament-director-attributes-module--pointer--cc638";
export var primaryBorder = "tournament-director-attributes-module--primary-border--c76e2";
export var shadowBoxLight = "tournament-director-attributes-module--shadow-box-light--d117a";
export var siteFont = "tournament-director-attributes-module--site-font--41c06";
export var slideDownAndFade = "tournament-director-attributes-module--slideDownAndFade--30148";
export var slideLeftAndFade = "tournament-director-attributes-module--slideLeftAndFade--eb228";
export var slideRightAndFade = "tournament-director-attributes-module--slideRightAndFade--19525";
export var slideUpAndFade = "tournament-director-attributes-module--slideUpAndFade--4622f";
export var statusDecoration = "tournament-director-attributes-module--status-decoration--2d98c";
export var textInput = "tournament-director-attributes-module--text-input--c7f16";
export var textInverted = "tournament-director-attributes-module--text-inverted--bc6f6";
export var textMediumDark = "tournament-director-attributes-module--text-medium-dark--d4221";
export var tooltipFont = "tournament-director-attributes-module--tooltipFont--bf809";
export var unstyledButton = "tournament-director-attributes-module--unstyled-button--3aa5f";