import React, { useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { SafePlayStatus as SafePlay } from 'src/graphql-types/globalTournamentTypes';

interface Props {
  status?: SafePlay | null;
}

const SafePlayStatus: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation();
  const statusText = useMemo(() => {
    switch (status) {
      case SafePlay.APPROVED:
        return t('approved');
      case SafePlay.EXPIRED:
        return t('expired');
      case SafePlay.NOTAPPROVED:
        return t('not approved');
      case SafePlay.PENDING:
        return t('pending');
      case SafePlay.NOTSTARTED:
        return t('not started');
      default:
        return t('not certified');
    }
  }, [status, t]);
  return <>{statusText}</>;
};

export default SafePlayStatus;
