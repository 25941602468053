import React from 'react';

import 'react-datepicker/dist/react-datepicker-cssmodules.css';

import cx from 'classnames';
import { SpacingProps, useSpacing } from 'src/hooks/spacing';

import * as styles from './filter-set.module.less';

const FilterSet: React.FC<SpacingProps> = ({ children, spacing }) => {
  const spacingClass = useSpacing(spacing);
  return <div className={cx(styles.filterSet, spacingClass)}>{children}</div>;
};

interface FilterProps {
  label?: string;
}

export const Filter: React.FC<FilterProps> = ({ label, children }) => {
  return (
    <div className={styles.filter}>
      <label>{label}</label>
      <div className={styles.filterInputs}>{children}</div>
    </div>
  );
};

export default FilterSet;
